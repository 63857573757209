export const LOGIN_URL = "/userLogin";
export const FETCH_OWNER_URL = "/user/auth/profile";
export const CHANGE_PASSWORD_URL = "/user/profile/changePassword";

export const FETCH_ACCOUNTS_URL = "/user/account/fetchAccount";
export const GENERATE_BILL_URL = "/User/Account/Generate";
export const FETCH_LIVE_ACCOUNTS_URL = "/user/account/fetchLiveAccount";

export const FETCH_USER_URL = "/user/user/fetchById";
export const FETCH_CLIENT_URL = "/user/client/fetchById";
export const DOWNLOAD_CLIENT_CSV_URL = "/user/client/fetchExcel";
export const FETCH_CLIENT_TRANSACTIONS_URL = "/User/Client/Transactions";
export const DELETE_CLIENT_URL = "/user/client/delete";
export const FETCH_CLIENT_M2M_URL = "/User/Client/M2M";

export const FETCH_DASHBOARD_DATA_URL = "/user/profile/dashboard";

export const FETCH_USERS_URL = "/user/user/fetch";
export const FETCH_ALL_UNDER_USERS_URL = "/User/Fetch/Under";
export const CREATE_USER_URL = "/user/user/create";
export const UPDATE_USER_URL = "/user/user/update";
export const FETCH_BROKERS_URL = "/user/user/fetchBroker";
export const DELETE_USER_URL = "/user/user/delete";

export const CREATE_CLIENT_URL = "/user/client/create";
export const UPDATE_CLIENT_URL = "/user/client/update";
export const FETCH_ALL_UNDER_CLIENTS_URL = "/user/client/fetch";
export const CREATE_CUSTOM_POSITION_URL = "/User/Client/CreatePosition";
export const FETCH_SINGLE_CLIENT_M2M = "/user/client/singleClientM2M";

export const FETCH_TEMPLATES_URL = "/user/brokerage/fetch";
export const CREATE_BROKERAGE_URL = "/user/brokerage/create";
export const FETCH_SINGLE_TEMPLATE_URL = "/user/brokerage/fetchById";
export const UPDATE_BROKERAGE_TEMPLATE = "/user/brokerage/update";
export const DELETE_BROKERAGE_TEMPLATE_URL = "/user/brokerage/delete";

export const FETCH_MARGIN_TEMPLATES_URL = "/user/margin/fetch";
export const CREATE_MARGIN_URL = "/user/margin/create";
export const FETCH_SINGLE_MARGIN_TEMPLATE_URL = "/user/margin/fetchById";
export const UPDATE_MARGIN_TEMPLATE = "/user/margin/update";
export const FETCH_GLOBAL_MARGIN_URL = "/User/Margin/GlobalMargin";
export const CREATE_GLOBAL_MARGIN_URL = "/User/Margin/Create/Global";
export const DELETE_MARGIN_URL = "/user/margin/delete";
export const DELETE_GLOBAL_MARGIN_URL = "/User/GlobalMargin/Delete";

export const CREATE_USER_FUND_URL = "/user/fund/createUserFund";
export const CREATE_CLIENT_FUND_URL = "/user/fund/createClientFund";
export const FETCH_TRANSACTIONS_URL = "/user/fund/transactions";
export const FETCH_PAYOUT_REQUESTS_URL = "/user/fund/getPayoutRequest";
export const REJECT_PAYOUT_REQUESTS_URL = "/user/fund/cancelPayoutRequest";
export const APPROVE_PAYOUT_REQUESTS_URL = "/user/fund/approvePayoutRequest";

export const FETCH_SCRIPTS_URL = "/user/script/fetch";
// export const ACTIVATE_SCRIPT_URL = "/User/Script/Activate";
// export const DEACTIVATE_SCRIPT_URL = "/User/Script/DeActivate";
export const CHANGE_SCRIPT_STATUS_URL = "/user/script/changeStatus";
export const FETCH_SYMBOLS_URL = "/user/symbol/fetch";
export const FETCH_MARKET_INSTRUMENTS_URL = "/user/script/fetchInstrument";
export const FETCH_TIMING_URL = "/user/timing/fetch";
export const UPDATE_TIMING_URL = "/user/timing/update";

export const FETCH_GLOBAL_SETTING_URL = "/user/fetchGlobalSetting";
export const UPDATE_GLOBAL_SETTING_URL = "/user/updateGlobalSetting";

export const FETCH_ACTIVE_SCRIPTS_URL = "/user/position/fetch";
export const FETCH_CLOSE_SCRIPT_URL = "/user/trade/closeScript";
export const FETCH_ACTIVE_SCRIPT_CLIENTS_URL = "/User/Position/ActiveScriptClients";
export const FETCH_TRADES_URL = "/user/trade/fetch";
export const FETCH_ORDERS_URL = "/user/order/fetch";
export const PUSH_CLIENT_ORDER_URL = "/user/order/push";
export const CREATE_TRADE_URL = "/User/Trade/Create";
export const EXPORT_TRADES_URL = "/User/Trade/Custom";
export const DELETE_TRADE_URL = "/User/Trade/Delete";
export const MODIFY_TRADE_URL = "/User/Trade/Modify";

export const FETCH_MCX_SYMBOLS_URL = "/Exchange/Instrument/Symbol/Mcx";
export const MODIFY_ORDER_URL = "/User/Order/Modify";
export const CANCEL_ORDER_URL = "/User/Order/Cancel";


export const SEARCH_USER_BY_USERNAME_URL = "/user/user/searchUserByUsername";
export const SEARCH_CLIENT_BY_USERNAME_URL = "/user/client/searchClientByUsername";

export const FETCH_CURRENCY_RATE_URL = "/user/fund/fetchCurrencyRate";
export const UPDATE_CURRENCY_RATE_URL = "/user/fund/updateCurrencyRate";

export const FETCH_BILL_DATE_HISTORY_URL = "/user/bill/getBillDateHistoryWithClientId";
export const FETCH_BILL_PDF_URL = "/user/bill/getBill";

export const FETCH_LEDGER_MASETR_URL = "/user/client/fetchLedgerMasterByClientId";
export const FETCH_POSITIONS_URL = "/user/position/fetchPosition";

export const FETCH_REFERRAL_SETTING_URL = "/user/refferal/fetchSettings";
export const UPDATE_REFERRAL_SETTINGS_URL = "/user/refferal/updateSettings";

export const FETCH_BLOCK_SCRIPTS_URL = "/user/script/fetchBlockedScripts";
export const BLOCK_SCRIPT_URL = "/user/script/blockScript";
export const UNBLOCK_SCRIPTS_URL = "/user/script/unblockScript";

export const FETCH_DIVIDEND_URL = "/user/dividend/fetchDividend";
export const ADD_DIVIDEND_URL = "/user/dividend/addDividend";
export const DELETE_DIVIDEND_URL = "/user/dividend/removeDividend";

export const FETCH_REJECTION_LOGS_URL = "/user/client/fetchRejectionLogsByClientId";

//comands
export const PM2_RESTART_ALL_URL = "/User/Command/pm2RestartAll";