import React, { Fragment, useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import {
  FETCH_TRADES_URL,
  EXPORT_TRADES_URL,
  DELETE_TRADE_URL,
} from "../../../_helper/url_helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { useDraggable } from "react-use-draggable-scroll";
import CreateTradeModal from "./CreateTradeModal";
import ModifyTradeModal from "./ModifyTradeModal";
import { CSVLink } from "react-csv";

const GeneralComponent = () => {
  const loginState = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [trades, setTrades] = useState([]);
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  const [createTradeModalStatus, setCreateTradeModalStatus] = useState(false);
  const [modifyTradeModalStatus, setModifyTradeModalStatus] = useState(false);
  const [activeTradeId, setActiveTradeId] = useState(0);
  const [activeQty, setActiveQty] = useState(0);
  const [activeBuyPrice, setActiveBuyPrice] = useState(0);
  const [activeSellPrice, setActiveSellPrice] = useState(0);
  const [activeBrokerage, setActiveBrokerage] = useState(0);
  const [username, setUsername] = useState("");
  const usernameRef = useRef(0);
  usernameRef.current = username;
  const [script, setScript] = useState("");
  const [minFilter, setMinFilter] = useState("");
  const minFilterRef = useRef(0);
  minFilterRef.current = minFilter;
  const scriptRef = useRef(0);
  scriptRef.current = script;
  const [exchange, setExchange] = useState("all");
  const exchangeRef = useRef(0);
  exchangeRef.current = exchange;
  const [csvData, setCsvData] = useState([]);
  const csvButtonRef = useRef(null);
  const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });

  useEffect(() => {
    fetchTradesHandler();
  }, []);

  function setModifyTradeModal(tradeId, buyPrice, sellPrice, qty, brokerage) {
    setActiveTradeId(tradeId);
    setActiveBuyPrice(buyPrice);
    setActiveSellPrice(sellPrice);
    setActiveQty(qty);
    setActiveBrokerage(brokerage);
    setModifyTradeModalStatus(true);
  }

  // useEffect(() => {
  //   let interval = setInterval(fetchTradesHandler, 20000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (createTradeModalStatus === false) {
      fetchTradesHandler();
    }
  }, [createTradeModalStatus]);

  useEffect(() => {
    if (modifyTradeModalStatus === false) {
      fetchTradesHandler();
    }
  }, [modifyTradeModalStatus]);

  function setPage(page) {
    if (page < 1 || page > pagination.total) {
      return;
    }
    // setPagination({ ...pagination, current: page });
    fetchTradesHandler(page);
  }

  async function fetchTradesHandler(page = 1) {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      let data = {};
      if (usernameRef.current !== "") {
        data["username"] = usernameRef.current;
      }
      if (scriptRef.current !== "") {
        data["script"] = scriptRef.current;
      }
      if (exchangeRef.current !== "all") {
        data["exchange"] = exchangeRef.current;
      }
      if (minFilterRef.current !== "") {
        data["close_min"] = minFilterRef.current;
      }
      data["page"] = page;
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_TRADES_URL, { params: data });
      if (res.data.status === "SUCCESS") {
        if (res.data.payload.data !== undefined) {
          setTrades(res.data.payload.data);
          setPagination({ current: res.data.payload.current_page, total: res.data.payload.last_page, totalRows: res.data.payload.total });
        } else {
          setTrades([]);
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function exportTrades() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        start_date: Math.round(startDate.getTime() / 1000),
        end_date: Math.round(endDate.getTime() / 1000),
      };
      let res = await api.post(EXPORT_TRADES_URL, data);
      if (res.data.status === 1) {
        setCsvData(res.data.data);
        csvButtonRef.current.link.click();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function deleteTradesHandler(tradeId) {
    try {
      if (!window.confirm(`Really want to delete trade no ${tradeId}?`)) {
        return;
      }
      let data = {
        jwttoken: loginState.jwttoken,
        trade_id: tradeId
      };
      let res = await api.post(DELETE_TRADE_URL, data);
      if (res.data.status === 1) {
        fetchTradesHandler();
        toast.success(`Trade no ${tradeId} Deleted`);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      {/* <CreateTradeModal
        createTradeModalStatus={createTradeModalStatus}
        setCreateTradeModalStatus={setCreateTradeModalStatus}
      />
      <ModifyTradeModal
        activeTradeId={activeTradeId}
        activeBuyPrice={activeBuyPrice}
        activeSellPrice={activeSellPrice}
        activeQty={activeQty}
        activeBrokerage={activeBrokerage}
        modifyTradeModalStatus={modifyTradeModalStatus}
        setModifyTradeModalStatus={setModifyTradeModalStatus}
      /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader
                    className="bg-secondary"
                    onClick={() => toggle(1)}
                  >
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen === 1 ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen === 1}>
                    <CardBody>
                      <Row className="row">
                        <Col className="col mb-3" md={4}>
                          <label className="col-form-label form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter username"
                            value={username}
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                          />
                        </Col>

                        <Col className="col" md={4}>
                          <label className="col-form-label form-label">
                            Script
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter script"
                            value={script}
                            onChange={(e) => {
                              setScript(e.target.value);
                            }}
                          />
                        </Col>

                        <Col className="col" md={4}>
                          <label className="col-form-label form-label">
                            Exchange
                          </label>
                          <select
                            className="form-control"
                            value={exchange}
                            onChange={(e) => {
                              setExchange(e.target.value);
                            }}
                          >
                            <option value="all">All</option>
                            <option value="MCX">MCX</option>
                            <option value="NFO">NFO</option>
                            <option value="CDS">CDS</option>
                            <option value="FX">FX</option>
                            <option value="CRYPTO">CRYPTO</option>
                            <option value="US">US</option>
                          </select>
                        </Col>

                        <Col className="col" md={4}>
                          <label className="col-form-label form-label">
                            Trade close in min
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter minutes"
                            value={minFilter}
                            onChange={(e) => {
                              setMinFilter(e.target.value);
                            }}
                          />
                        </Col>

                        <div className="col row align-items-end">
                          <div>
                            <button
                              className="btn btn-primary m-t-5"
                              onClick={() => { fetchTradesHandler(pagination.current) }}
                            >
                              Search
                            </button>

                            {/* {(username !== "" || script !== "" || exchange !== "all") && <button className="btn btn-danger mx-4 m-t-5" onClick={()=>{setUsername(""); setScript(""); setExchange(""); fetchTradesHandler();}}>
                              Reset
                            </button>} */}
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="row">
                  <Col className="col" md={4}>
                    <label className="col-form-label form-label">
                      From Date
                    </label>
                    <DatePicker
                      className="form-control digits"
                      selected={startDate}
                      onChange={(date) => setstartDate(date)}
                      placeholderText="mm/dd/yyyy"
                    />
                  </Col>

                  <Col className="col" md={4}>
                    <label className="col-form-label form-label">To Date</label>
                    <DatePicker
                      className="form-control digits"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText="mm/dd/yyyy"
                    />
                  </Col>

                  <div className="col row align-items-end">
                    <div>
                      <button
                        className="btn btn-primary m-t-5"
                        onClick={exportTrades}
                      >
                        Export all to excel
                      </button>
                      <CSVLink
                        data={csvData}
                        filename={"trades.csv"}
                        className="d-none"
                        target="_blank"
                        ref={csvButtonRef}
                      >
                        Download me
                      </CSVLink>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        <Row>
          <Col sm="12">
            <Card>
              {/* <CardBody> */}
              <CardHeader className="py-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Trades</h5>
                  {/* {loginState.owner.type === 'super' && <button
                    className="btn btn-primary"
                    onClick={() => {
                      setCreateTradeModalStatus(true);
                    }}
                  >
                    Create
                  </button>} */}
                </div>
              </CardHeader>
              <div className="table-responsive" ref={ref} {...events}>
                <Table className="table-border-horizontal fw-semibold align-middle" striped>
                  <thead>
                    <tr className="bg-primary">
                      {/* {(loginState.owner.type === "super") && <th style={{ color: "#ffffff" }}>Actions</th>} */}
                      <th style={{ color: "#ffffff" }}>ID</th>
                      <th style={{ color: "#ffffff" }}>Script</th>
                      <th style={{ color: "#ffffff" }}>Exchange</th>
                      <th style={{ color: "#ffffff" }}>Currency</th>
                      <th style={{ color: "#ffffff" }}>User</th>
                      <th style={{ color: "#ffffff" }}>Qty</th>
                      <th style={{ color: "#ffffff" }}>Type</th>
                      <th style={{ color: "#ffffff" }}>BuyRate</th>
                      <th style={{ color: "#ffffff" }}>SellRate</th>
                      <th style={{ color: "#ffffff" }}>Profit/Loss</th>
                      <th style={{ color: "#ffffff" }}>Brokerage</th>
                      <th style={{ color: "#ffffff" }}>Net</th>
                      <th style={{ color: "#ffffff" }}>UsedMargin</th>
                      <th style={{ color: "#ffffff" }}>BoughtAt</th>
                      <th style={{ color: "#ffffff" }}>SoldAt</th>
                      <th style={{ color: "#ffffff" }}>isSettled</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trades.length
                      ? trades.map((trade, idx) => {
                        return (
                          <tr key={trade.id}>
                            {/* {(loginState.owner.type === "super") && <td>{actionBinder(trade, deleteTradesHandler, setModifyTradeModal)}</td>} */}
                            <td>{trade.id}</td>
                            <td>{trade.trading_symbol}</td>
                            <td>{trade.exchange}</td>
                            <td>{trade.currency}</td>
                            <td>{trade.client_name}</td>
                            <td>{trade.qty}</td>
                            <td className={`fw-semibold ${(trade.type === "buy") ? "text-success" : "text-danger"}`}>{trade.type}</td>
                            <td>{trade.buy_price}</td>
                            <td>{trade.sell_price}</td>
                            <td
                              className={`${trade.profit_and_loss > 0
                                ? "text-success"
                                : "text-danger"
                                }`}
                            >
                              {trade.profit_and_loss}
                            </td>
                            <td>{trade.brokerage}</td>
                            <td
                              className={`${Number(trade.profit_and_loss) -
                                Number(trade.brokerage) >
                                0
                                ? "text-success"
                                : "text-danger"
                                }`}
                            >
                              {parseFloat(
                                Number(trade.profit_and_loss) -
                                Number(trade.brokerage)
                              ).toFixed(2)}
                            </td>
                            <td>{trade.used_margin}</td>
                            <td className="text-nowrap">{trade.bought_at}</td>
                            <td className="text-nowrap">{trade.added_on}</td>
                            <td>{trade.is_settled}</td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </Table>
              </div>
              <div className="pagination mt-3 d-flex justify-content-between align-items-center mb-3 px-3" >
                <div>
                  <span className="text-semibold">Page {pagination.current} out of {pagination.total}</span>
                </div>
                <div>
                  <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current - 1) }}>Prev</button>
                  <input type="number" className="form-control d-inline mx-3 text-center fw-semibold" style={{ maxWidth: '100px' }} min="1" value={pagination.current} onChange={(e) => { setPage(e.target.value) }} />
                  {/* <span>of</span> */}
                  {/* <span id="totalPages">10</span> */}
                  <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current + 1) }}>Next</button>
                </div>
              </div>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;



function actionBinder(trade, deleteTradesHandler, setModifyTradeModal) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">
        <li className="list-inline-item edit">
          <button
            title="update"
            className="btn btn-sm btn-secondary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { setModifyTradeModal(trade.id, trade.buy_price, trade.sell_price, trade.qty, trade.brokerage); }}
          >
            <i className="fa fa-pencil"></i>
          </button>
        </li>
        <li>
          <button
            title="Delete"
            className="btn btn-sm btn-outline-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { deleteTradesHandler(trade.id) }}
          >
            <i className="fa fa-trash-o"></i>
          </button>
        </li>
      </ul>
    </div>
  );
}
