import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Collapse, CardBody, Card, CardHeader, Container, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_TRANSACTIONS_URL } from "../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  // const [data, setData] = useState([]);
  const [clientTransactions, setClientTransactions] = useState([]);
  const [userTransactions, setUserTransactions] = useState([]);
  const [note, setNote] = useState("");
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [clientPagination, setClientPagination] = useState({ current: 1, total: 1, totalRows: 0 });
  const [userPagination, setUserPagination] = useState({ current: 1, total: 1, totalRows: 0 });
  const [transactionState, setTransactionState] = useState("client");
  const [filters, setFilters] = useState({ username: "", script: "", status: "all" });
  const [isOpen, setIsOpen] = useState(false);

  //fetch first time users
  useEffect(() => {
    fetchClientsTransactionsHandler();
    fetchUserTransactionsHandler();
  }, []);

  function changeNoteModal(note) {
    setNote(note);
    setNoteModalOpen(true);
  }

  function setClientPage(page) {
    if (page < 1 || page > clientPagination.total) {
      return;
    }
    // setPagination({ ...pagination, current: page });
    fetchClientsTransactionsHandler(page);
  }

  async function fetchClientsTransactionsHandler(page = 1) {
    try {
      let data = {
        page: page
      };
      if (filters.username) {
        data.client_username = filters.username;
      }
      if (filters.status && filters.status != 'all') {
        data.client_status = filters.status;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(`${FETCH_TRANSACTIONS_URL}`, { params: data });
      if (res.data.status == "SUCCESS") {
        setClientTransactions(res.data.payload.client.data);
        setClientPagination({ current: res.data.payload.client.current_page, total: res.data.payload.client.last_page, totalRows: res.data.payload.client.total });
        // console.log(res.data.payload.client.total);
        // setUserTransactions(res.data.payload.user.data);
        // setData(res.data.payload[transactionState]);

      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  function setUserPage(page) {
    if (page < 1 || page > userPagination.total) {
      return;
    }
    // setPagination({ ...pagination, current: page });
    fetchUserTransactionsHandler(page);
  }

  async function fetchUserTransactionsHandler(page = 1) {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(`${FETCH_TRANSACTIONS_URL}?page=${page}`);
      if (res.data.status == "SUCCESS") {
        // setClientTransactions(res.data.payload.client.data);
        if (res.data.payload.user) {
          setUserTransactions(res.data.payload.user.data);
          setUserPagination({ current: res.data.payload.user.current_page, total: res.data.payload.user.last_page, totalRows: res.data.payload.user.total });
        } // setData(res.data.payload[transactionState]);

      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Modal isOpen={noteModalOpen} toggle={() => { setNoteModalOpen(false) }}>
          <ModalHeader>Note</ModalHeader>
          <ModalBody className="fw-semibold f-16">{note}</ModalBody>
          <ModalFooter><button className="btn btn-dark btn-sm" onClick={() => { setNoteModalOpen(false) }}>Close</button></ModalFooter>
        </Modal>

        <Row>
          <Col sm="12">
            {/* <Accordion defaultActiveKey="0"> */}
            <div className="default-according" id="accordion1">
              <Card className="mb-4">
                <CardHeader
                  className="bg-secondary"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <h5 className="d-flex justify-content-between">
                    <span>Filters</span>
                    {isOpen ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                  <CardBody>
                    <div className="row">
                      {/* <div className="col">
                          <label className="col-form-label form-label">
                            Search Script
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Script"
                          />
                        </div> */}

                      <div className="col">
                        <label className="col-form-label form-label">
                          Status
                        </label>
                        <select value={filters.status} className="form-control" onChange={(e) => { setFilters({ ...filters, status: e.target.value }) }}>
                          <option value="all">All</option>
                          <option value="pending">Pending</option>
                          <option value="complete">Complete</option>
                          <option value="cancel">Canceled</option>
                        </select>
                      </div>

                      <div className="col">
                        <label className="col-form-label form-label">
                          Username
                        </label>
                        <input type="text" placeholder="Enter username" value={filters.username} className="form-control" onChange={(e) => { setFilters({ ...filters, username: e.target.value }) }} />
                      </div>
                      <div className="my-2"></div>
                      <div className="col">
                        <div>
                          <button
                            className="btn btn-primary m-t-5"
                            onClick={() => { fetchClientsTransactionsHandler(1) }}
                          >
                            Search
                          </button>

                          {/* {(username !== "" || script !== "" || exchange !== "all") && <button className="btn btn-danger mx-4 m-t-5" onClick={()=>{setUsername(""); setScript(""); setExchange(""); fetchTradesHandler();}}>
                              Reset
                            </button>} */}
                        </div>
                      </div>
                      {/* <div className="col">
                        <label className="col-form-label form-label">
                          Script
                        </label>
                        <input type="text" placeholder="Enter script" value={filters.script} className="form-control" onChange={(e) => { setFilters({ ...filters, script: e.target.value }) }} />
                      </div> */}

                      {/* <div className="col row align-items-end">
                          <div>
                            <button className="btn btn-danger m-t-5">
                              Reset
                            </button>
                          </div>
                        </div> */}
                      <div className="col"></div>
                    </div>
                  </CardBody>
                </Collapse>
              </Card>
            </div>
            {/* </Accordion> */}
          </Col>
        </Row>


        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0 mb-3 pt-4">
                <div className="justify-content-between row align-items-center">
                  <div className="col-4 col">
                    <h5 className="m-0 p-0 d-inline-block">
                      <i className="fa fa-users"></i> Transactions{" "}
                    </h5>{" "}
                    <span className="badge rounded-pill badge bg-primary rounded-pill">
                      {(transactionState === 'client') ? clientPagination.totalRows : userPagination.totalRows}
                    </span>
                  </div>
                  <div className="col-4 col text-end">
                    <button onClick={() => { setTransactionState("client") }} className={`btn btn-${(transactionState === "client") ? "" : "outline-"}secondary`}>
                      Client
                    </button>
                    <button onClick={() => { setTransactionState("user") }} className={`mx-3 btn btn-${(transactionState === "user") ? "" : "outline-"}secondary`}>
                      User
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/fund/create")}
                    >
                      <i className="fa fa-plus"></i> Create
                    </button>
                  </div>
                </div>
              </CardHeader>
              {transactionState === 'client' && <div className="table-responsive">
                <table className="table-border-horizontal w-100 fw-semibold table-striped text-nowrap">                  <thead>
                  <tr>
                    <td>#</td>
                    <td>TID</td>
                    <td>RECIEVER</td>
                    <td>SENDER</td>
                    <td>METHOD</td>
                    <td>TYPE</td>
                    <td>AMOUNT</td>
                    <td>CURRENCY</td>
                    <td>DATE</td>
                    <td>NOTE</td>
                    <td>STATUS</td>
                    <td>GID</td>
                    <td>UTR</td>
                  </tr>
                </thead>
                  <tbody>
                    {clientTransactions.map((transaction) => {
                      return (
                        <>
                          <tr>
                            <td>{typeIconFormatter(transaction)}</td>
                            <td>{transaction.id}</td>
                            <td>{transaction.reciever_username}</td>
                            <td>{transaction.sender_username}</td>
                            <td>{transaction.method}</td>
                            <td>{transaction.type}</td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.currency}</td>
                            <td>{transaction.added_on}</td>
                            <td>{(transaction.note) ? <button className="btn btn-xs btn-primary" onClick={() => { changeNoteModal(transaction.note) }}>Note</button> : <i className="icofont icofont-emo-slightly-smile f-16"></i>}</td>
                            <td>{(transaction.status === "pending") ? <span className="badge  btn-sm badge-warning fw-semibold f-14">PENDING</span> : (transaction.status === "complete") ? <span className="badge  btn-sm badge-primary fw-semibold f-14">COMPLETE</span> : <span className="badge btn-sm badge-danger fw-semibold f-14">CANCEL</span>}</td>
                            <td>{transaction.gateway_order_id}</td>
                            <td>{transaction.utr}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              }
              {transactionState === 'user' && <div className="table-responsive">
                <table className="table-border-horizontal w-100 fw-semibold table-striped text-nowrap">                  <thead>
                  <tr>
                    <td>#</td>
                    <td>TID</td>
                    <td>RECIEVER</td>
                    <td>SENDER</td>
                    <td>TYPE</td>
                    <td>AMOUNT</td>
                    <td>CURRENCY</td>
                    <td>DATE</td>
                    <td>NOTE</td>
                    <td>STATUS</td>
                  </tr>
                </thead>
                  <tbody>
                    {userTransactions.map((transaction) => {
                      return (
                        <>
                          <tr>
                            <td>{typeIconFormatter(transaction)}</td>
                            <td>{transaction.id}</td>
                            <td>{transaction.reciever_username}</td>
                            <td>{transaction.sender_username}</td>
                            <td>{transaction.type}</td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.currency}</td>
                            <td>{transaction.added_on}</td>
                            <td>{(transaction.note) ? <button className="btn btn-xs btn-primary" onClick={() => { changeNoteModal(transaction.note) }}>Note</button> : <i className="icofont icofont-emo-slightly-smile f-16"></i>}</td>
                            <td>{(transaction.status === "pending") ? <span className="badge  btn-sm badge-warning fw-semibold f-14">PENDING</span> : (transaction.status === "complete") ? <span className="badge  btn-sm badge-primary fw-semibold f-14">COMPLETE</span> : <span className="badge btn-sm badge-danger fw-semibold f-14">CANCEL</span>}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>}

              {transactionState === 'client' && <div className="pagination my-3 mx-3 d-flex justify-content-between align-items-center" >
                <div>
                  <span className="text-semibold">Page {clientPagination.current} out of {clientPagination.total}</span>
                </div>
                <div>
                  <button className="btn btn-outline-primary me-3" onClick={() => { setClientPage(1) }}>First</button>
                  <button className="btn btn-outline-primary" onClick={() => { setClientPage(clientPagination.current - 1) }}>Prev</button>
                  <input type="number" className="form-control d-inline mx-3 text-center fw-semibold" style={{ maxWidth: '100px' }} min="1" value={clientPagination.current} onChange={(e) => { setClientPage(e.target.value) }} />
                  {/* <span>of</span> */}
                  {/* <span id="totalPages">10</span> */}
                  <button className="btn btn-outline-primary" onClick={() => { setClientPage(clientPagination.current + 1) }}>Next</button>
                  <button className="btn btn-outline-primary ms-3" onClick={() => { setClientPage(clientPagination.total) }}>Last</button>
                </div>
              </div>}

              {transactionState === 'user' && <div className="pagination my-3 mx-3 d-flex justify-content-between align-items-center" >
                <div>
                  <span className="text-semibold">Page {userPagination.current} out of {userPagination.total}</span>
                </div>
                <div>
                  <button className="btn btn-outline-primary" onClick={() => { setUserPage(userPagination.current - 1) }}>Prev</button>
                  <input type="number" className="form-control d-inline mx-3 text-center fw-semibold" style={{ maxWidth: '100px' }} min="1" value={userPagination.current} onChange={(e) => { setUserPage(e.target.value) }} />
                  {/* <span>of</span> */}
                  {/* <span id="totalPages">10</span> */}
                  <button className="btn btn-outline-primary" onClick={() => { setUserPage(userPagination.current + 1) }}>Next</button>
                </div>
              </div>}

            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );
};

export default GeneralComponent;

function typeIconFormatter(row) {
  if (row.type === "deposit") {
    return (
      <div
        className="d-flex justify-content-center align-items-center text-success btn-pill f-16"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "rgba(36,105,92,.1)",
        }}
      >
        <i className="fa fa-angle-double-left"></i>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center text-danger btn-pill f-16"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "rgba(220,53,69,.1)",
        }}
      >
        <i className="fa fa-angle-double-right"></i>
      </div>
    );
  }

}
